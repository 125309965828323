import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '@/components/HeaderAlt'
import Footer from '@/components/Footer'

const BaseLayout: React.FC = ({ children }) => (
  <>
    <Helmet>
      <meta name="robots" content="noindex,follow" />
    </Helmet>
    <Header />
    {children}
    <Footer />
  </>
)

export default BaseLayout
